import React from "react";
import Layout from "../components/layout";
import WorkList from "../components/workList";
import SubHeader from "../components/subheader";

const PortfolioPage = () => (
  <Layout>
    <SubHeader
      title="Clients"
      description="I've had the pleasure of working with some truly outstanding
      businesses and entrepreneurs over the years. Recent clients listed below."
      link="portfolio"
    />
    <div className="container py-5">
      <div className="row">
        <div className="col-md-12 col-lg-8 offset-lg-2 text-center">
          <WorkList />
        </div>
      </div>
    </div>
  </Layout>
);

export default PortfolioPage;
