
import React from "react"
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from "gatsby-image"

const WorkList = (props) => (
    <div {...props}>
        <StaticQuery
            query={graphql`
            query AllWorksQuery {
                allWorksYaml{
                    nodes {
                        id,
                        brand,
                        url,
                        tags,
                        brandLogo {
                            id
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                    originalImg
                                }
                            }
                        }
                    }
                }
            }
            `}
            render={data => (
                <div className="gallery" id="workList">
                    {data.allWorksYaml.nodes.map((node, index) => (
                        <div className="cell" key={index}>
                            <div>
                                <Img fluid={node.brandLogo.childImageSharp.fluid}  className="center-img"/>    
                            </div>
                            <p className="text-center">
                                {node.brand}
                            </p>
                            {node.url && (
                            <p className="text-center">
                                <small>
                                    <a href={node.url}>{node.url}</a>
                                </small>
                            </p>
                            )}
                            <small className="text-muted">
                                {node.tags.map((tag, index) => (<span key={index} className="no-hover" >{tag}</span>)).reduce((prev, curr) => [prev, ' / ', curr])}
                            </small>
                        </div>
                    ))}
                </div>
            )}
        />
    </div>
);

export default WorkList;
